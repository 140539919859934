<template>
  <page>
    <module enable-flex>
      <m-search ref="search" @search="searchHandle">
        <m-search-item label="品牌名称">
          <c-input name="brand_name"></c-input>
        </m-search-item>
      </m-search>

      <m-operate>
        <c-button v-if="auth('b_brand_edit')" color="sys" @click="createbrand">新建品牌</c-button>
      </m-operate>

      <c-table ref="table" height="grow">

        <c-table-column
            label="品牌名称"
            width="120"
            name="brand_name"
        ></c-table-column>
       <!-- <c-table-column

            label="品牌LOGO"
            width="80"
            name="logo"
        >
	        <template #default="{data}">
		        <div class="flex-center-cross">
			        <c-file media :src="data.logo"></c-file>
		        </div>
	        </template>
        </c-table-column> -->

        <c-table-column
            label="备注"
            width="200"
            name="remark"
        >
          <template #default="{data}">
            <span v-if="data.remark">{{data.remark}}</span>
            <span v-else>--</span>
          </template>
        </c-table-column>
		
		<c-table-column
		    label="LOGO数量"
		    width="80"
		    name="logo_count"
		>
		</c-table-column>

		<c-table-column
		    label="创建时间"
		    width="140"
		    name="create_date"
		></c-table-column>

        <c-table-column
            type="button"
            label="操作"
            width="230"
        >
          <template v-slot="{data}">
            <c-table-button v-if="auth('b_brand_edit')" @click="editbrand(data)">编辑</c-table-button>
			<c-table-button v-if="auth('b_brand_edit')" @click="deletebrand(data)">删除</c-table-button>
			<c-table-button v-if="auth('b_brand_edit')" @click="openLogoDialog(data)">LOGO管理</c-table-button>
			<c-table-button v-if="auth('b_brand_edit')" @click="openCustomerDialog(data)">关联客户</c-table-button>
          </template>
        </c-table-column>
      </c-table>
    </module>

    <c-dialog ref="brandEdit" :title="editState ? '编辑品牌' : '新建品牌'" width="600" @resolve="submit">
      <c-form ref="brandForm" unit-width="100%">
        <c-form-item label="品牌名称" required>
          <c-input name="brand_name"></c-input>
        </c-form-item>
	    <!-- <c-form-item label="品牌LOGO" width="800">
		      <c-upload-file name="logo" media type="image" tip="请上传品牌LOGO图片，建议尺寸160×160px"></c-upload-file>
	    </c-form-item> -->
        <c-form-item label="备注">
	        <c-input type="textarea" name="remark"></c-input>
        </c-form-item>
        <c-input type="hidden" name="id"></c-input>
      </c-form>
    </c-dialog>
	
	<!--LOGO列表 -->
	<c-dialog ref="logoDialog" :title="this.brand_name+' - LOGO管理'" width="400">
		  <m-operate>
			  <c-button color="sys" @click="createLogo()">新增LOGO</c-button>
		  </m-operate>
		  <c-table ref="logoTable" height="400">
			  <c-table-column
			       label="LOGO"
			       width="60"
			       name="logo"
			   >
			       <template #default="{data}">
						<div class="flex-center-cross">
							<c-file media :src="data.logo" style="width:40px;height:40px;"></c-file>
						</div>
			       </template>
			   </c-table-column>
	
			  <c-table-column
				  label="描述"
				  width="220"
				  name="remark"
			  ></c-table-column>
	
			  <c-table-column
				  type="button"
				  label="操作"
				  width="120"
			  >
				  <template v-slot="{data}">
					  <c-table-button @click="editLogo(data)">编辑</c-table-button>
					  <c-table-button @click="deleteLogo(data)">删除</c-table-button>
				  </template>
			  </c-table-column>
		  </c-table>
	  </c-dialog>
	  
	  <!--新增、编辑品牌LOGO 弹框-->
	  <c-dialog ref="logoEdit" :title="editState ? '编辑品牌LOGO' : '新增品牌LOGO'" width="500" @resolve="submitLogo">
			<c-form ref="logoForm" unit-width="100%">
				<c-form-item label="LOGO" width="800" required>
				      <c-upload-file name="logo" media type="image" tip="请上传LOGO图片，建议尺寸160×160px"></c-upload-file>
				</c-form-item>
				<c-form-item label="备注">
				    <c-input type="textarea" name="remark"></c-input>
				</c-form-item>
				<c-input type="hidden" name="id"></c-input>
			</c-form>
	  </c-dialog>
	
	<!--关联客户列表 -->
	<c-dialog ref="customerDialog" :title="this.brand_name+' - 已关联商家'" width="800">
		  <m-search ref="customerSearch" @search="searchCustomerHandle">
			  <m-search-item label="客户名称">
				  <c-input name="name"></c-input>
			  </m-search-item>
			  <m-search-item label="客户编码">
				  <c-input name="code"></c-input>
			  </m-search-item>
		  </m-search>
		  <m-operate>
			  <c-button v-if="auth('b_brand_setcustomer')" color="sys" @click="setCustomer()">新增关联客户</c-button>
		  </m-operate>
		  <c-table ref="customerTable" height="400">
			  <c-table-column
				  label="客户名称"
				  width="150"
				  name="name"
			  ></c-table-column>

			  <c-table-column
				  label="客户编码"
				  width="100"
				  name="code"
			  ></c-table-column>

			  <c-table-column
				  label="客户联系人"
				  width="100"
				  name="contact_name"
			  ></c-table-column>

			  <c-table-column
				  label="联系方式"
				  width="140"
				  name="phone"
			  ></c-table-column>

			  <c-table-column
				  label="地址"
				  width="170"
				  name="address"
			  ></c-table-column>

			  <c-table-column
				  type="button"
				  label="操作"
				  width="80"
			  >
				  <template v-slot="{data}">
					  <c-table-button v-if="auth('b_brand_setcustomer')" @click="deleteBrandCustomer(data)">取消关联</c-table-button>
				  </template>
			  </c-table-column>
		  </c-table>
	  </c-dialog>
	<customer-selector ref="customerSelector" multiple @resolve="submitCustomerBrandConfig"></customer-selector>
  </page>
</template>

<script>
import customerSelector from '@/components/service/customer_selector.vue'  // 平台商品库
export default {
  name: 'm_brand',
  components: {
	  customerSelector
  },
  data() {
    return {
      editState: false,
	  brand_name:''
    }
  },
  computed: {
  },
  mounted() {
    this.$refs.search.search();
  },
  methods: {
    tabHandle(item) {
      this.$refs.search.search();
    },
    searchHandle(data) {
      this.$refs.table.load({
        url: '/dic/brand/list',
        data
      });
    },
    //创建品牌
    createbrand() {
      this.editState = false;
      this.$refs.brandForm.clear();
      this.$refs.brandForm.set({});
      this.$refs.brandEdit.open();
    },
    //编辑品牌
    editbrand(val) {
      this.editState = true;
      this.$refs.brandForm.set(val);
      this.$refs.brandEdit.open();
    },
	//删除品牌
	deletebrand(val){
		this.$confirm({
		  title: '确认',
		  message: '确定要删除品牌【'+val.brand_name+'】吗？',  // 提示内容,
		  buttonName: '确定',  // 确定按钮文字
		  cancelButton: true,  // 是否显示取消按钮
		  resolve: () => {
		    this.request({
		      url: '/dic/brand/delete',
		      data: {id:val.id},
		      loading: true,
		      success: data => {
		        this.$message({
		          message: '删除成功',
		          type: 'success'
		        });
				this.$refs.table.update();
		      }
		    });
		  },  // 确定按钮回调
		  cancel: () => {},  // 取消按钮回调
		});
	},
    //提交保存
    submit(stop) {
      stop();
      this.$refs.brandForm.submit({
        url: this.editState ? '/dic/brand/update' : '/dic/brand/add',
        rule: {
          brand_name: '请填写品牌名'
        },
        dataFormatter: data => {
          return data;
        },
        success: (data, res) => {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.$refs.table.update();
          this.$refs.brandEdit.close();
        }
      });
    },
	
	//打开logo管理弹窗
	openLogoDialog(data){
		this.$refs.logoDialog.open();
		this.brand_id = data.id;
		this.brand_name = data.brand_name;
		this.$refs.logoTable.load({
		  url: '/dic/brand/logo/list',
		  data: {
			  brand_id : this.brand_id
		  }
		});
	},
	//新增LOGO
	createLogo(){
		this.editState = false;
		this.$refs.logoForm.clear();
		this.$refs.logoEdit.open();
	},
	//修改LOGO
	editLogo(val){
		this.editState = true;
		this.$refs.logoForm.set(val);
		this.$refs.logoEdit.open();
	},
	//删除LOGO
	deleteLogo(val){
		this.$confirm({
			title: '确认',
			message: '确定要删除此LOGO吗？',  // 提示内容,
			buttonName: '确定',  // 确定按钮文字
			cancelButton: true,  // 是否显示取消按钮
			resolve: () => {
				this.request({
					url: '/dic/brand/logo/delete',
					data: {id:val.id},
					loading: true,
					success: data => {
						this.$message({
						  message: '删除成功',
						  type: 'success'
						});
						this.$refs.logoTable.update();
						this.$refs.table.update();
					}
				});
			},  // 确定按钮回调
			cancel: () => {},  // 取消按钮回调
		});
	},
	//提交保存LOGO
	submitLogo(stop) {
		stop();
		this.$refs.logoForm.submit({
			url: this.editState ? '/dic/brand/logo/edit' : '/dic/brand/logo/add',
			rule: {
				logo: '请上传LOGO'
			},
			dataFormatter: data => {
				data.brand_id = this.brand_id;
				return data;
			},
			success: (data, res) => {
				this.$message({
					message: '保存成功',
					type: 'success'
				});
				this.$refs.logoTable.update();
				this.$refs.table.update();
				this.$refs.logoEdit.close();
			}
		});
	},
	//打开关联客户弹窗
	openCustomerDialog(data){
		this.$refs.customerDialog.open();
		this.brand_id = data.id;
		this.brand_name = data.brand_name;
		this.$refs.customerSearch.search();
	},
	//设置品牌关联的客户信息
	setCustomer(){
		this.$refs.customerSelector.openExists(this.brand_id);
	},
	//提交【客户-品牌】关联数据
	submitCustomerBrandConfig(data) {
	  this.request({
		  url: '/dic/brand/set/customer',
		  data: {
			  brand_id: this.brand_id,
			  customer_ids: Array.from(data, item => {
				  return item.id
			  })
		  },
		  loading: true,
		  success: () => {
			  this.$refs.customerTable.update();
		  }
	  });
    },
	//搜索已关联客户
	searchCustomerHandle(data){
		  data.brand_id = this.brand_id;
		  this.$refs.customerTable.load({
			  url: '/customer/list',
			  data
		  });
	},
	//取消关联关系
	deleteBrandCustomer(data){
		this.$confirm({
			title: '确认',
			message: '是否确认取消品牌【'+this.brand_name+'】与客户【'+data.name+'】的关联关系？',  // 提示内容,
			buttonName: '确定',  // 确定按钮文字
			cancelButton: true,  // 是否显示取消按钮
			resolve: () => {
				this.request({
					url: '/dic/brand/remove/customer',
					data: {
						brand_id:this.brand_id,
						customer_id:data.id
					},
					loading: true,
					success: data => {
						this.$message({
							message: '取消成功',
							type: 'success'
						});
						this.$refs.customerTable.update();
					}
				});
			},  // 确定按钮回调
			cancel: () => {},  // 取消按钮回调
		});
	}
  }
}
</script>